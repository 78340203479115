.publications-container {
    padding: 20px;
  }
  
  .publications-list {
    list-style-type: none;
    padding: 0;
  }
  
  .publications-item {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .publications-item h3 {
    margin: 0 0 10px;
    color: #2980b9;
  }
  
  .publications-item a {
    color: #2980b9;
    text-decoration: none;
  }
  
  .publications-item a:hover {
    text-decoration: underline;
  }