footer {
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    text-align: center;
    margin-top: 40px;
  }
  
  .social-links a {
    margin-right: 20px;
    color: #3498db;
  }
  
  .social-links a:hover {
    color: #2980b9;
  }
  
  /* Created by Markaevi Bölümü */
  .created-by {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .created-by p {
    margin: 0 10px;
    color: #3498db;
  }
  
  .created-by a {
    text-decoration: none;
    color: #3498db;
  }
  
  .created-by a:hover {
    color: #2980b9;
  }
  
  .markaevi-logo {
    width: 40px;
    height: auto;
    margin-right: 10px;
  }