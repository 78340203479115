header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c3e50; /* Koyu arka plan */
    padding: 20px 40px;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .header-left h1 {
    font-size: 1.8rem;
    margin: 0;
    color: white;
  }
  
  .header-menu ul {
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .header-menu a {
    color: white;
    font-weight: 400;
    transition: color 0.3s ease;
  }
  
  .header-menu a:hover {
    color: #3498db; /* Hover'da mavi */
  }
  
  .header-right a {
    color: white;
    margin-left: 20px;
  }
  
  .header-right a:hover {
    color: #3498db;
  }