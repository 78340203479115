.homepage-container {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Intro Section (Fotoğraf ve tanıtım metni) */
  .intro-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
  }
  
  .intro-photo img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
    border: 4px solid #3498db;
  }
  
  .intro-text {
    max-width: 600px;
    margin-left: 40px;
  }
  
  .intro-text h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .intro-text p {
    font-size: 1.2rem;
    color: #2c3e50;
  }
  
  /* Research Explanation Section */
  .research-section {
    margin-bottom: 60px;
  }
  
  .research-section h2 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .research-section p {
    font-size: 1.2rem;
    color: #2c3e50;
    line-height: 1.6;
  }
  
  /* Highlights Section */
  .highlights-section h2 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .highlight-item {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .highlight-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Links Section */
  .links-section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .links-section li {
    display: inline-block;
    margin-right: 20px;
  }
  
  .links-section a {
    font-size: 1.2rem;
    color: #3498db;
  }
  
  .links-section a:hover {
    color: #2980b9;
  }
  
  /* Footer */
  .homepage-footer {
    text-align: center;
    margin-top: 40px;
  }
  
  .homepage-footer a {
    margin-right: 20px;
    color: #3498db;
  }
  
  .homepage-footer a:hover {
    color: #333;
  }